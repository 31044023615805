<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-combobox :value="field" :items="fieldOptions" label="Field to check" @change="selectField"></v-combobox>
    </v-col>
    <v-col v-if="opType === 'select'" cols="12" md="8">
      <v-select :value="value" :items="valueOptions" multiple label="Matching Value(s)" @change="(value) => $emit('change', { value })">
        <template v-slot:append-outer>
            <v-btn icon @click="$emit('remove')">
              <v-icon color="error">fal fa-times-circle</v-icon>
            </v-btn>
          </template>
      </v-select>
    </v-col>
    <v-col v-else-if="opType === 'checkbox'" cols="12" md="8">
      <v-select :value="value" :items="[{value:true,text:'Checked'},{value:false,text:'Not Checked'}]" label="Matching Value" @change="(value) => $emit('change', { value })">
        <template v-slot:append-outer>
            <v-btn icon @click="$emit('remove')">
              <v-icon color="error">fal fa-times-circle</v-icon>
            </v-btn>
          </template>
      </v-select>
    </v-col>
    <v-col v-else-if="opType === 'switch'" cols="12" md="8">
      <v-select :value="value" :items="[{value:true,text:'Switch On'},{value:false,text:'Switch Off'}]" label="Matching Value" @change="(value) => $emit('change', { value })">
        <template v-slot:append-outer>
            <v-btn icon @click="$emit('remove')">
              <v-icon color="error">fal fa-times-circle</v-icon>
            </v-btn>
          </template>
      </v-select>
    </v-col>
    <template v-else>
      <v-col cols="12" md="2">
        <v-select :value="op" :items="operators" label="Operator" @change="(op) => op == null || $emit('change', { op })"></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field :type="opType" :value="value" label="Value" @change="(value) => $emit('change', { value })">
          <template v-slot:append-outer>
            <v-btn icon @click="$emit('remove')">
              <v-icon color="error">fal fa-times-circle</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import { ref, onBeforeMount } from '@vue/composition-api'
export default {
  props: {
    field: String,
    op: String,
    value: [String, Number, Array, Boolean],
    fieldOptions: Array
  },
  setup (props, { root, emit }) {
    const opType = ref('text')

    const operators = ref([
      { text: 'Equal', value: '=' },
      { text: 'Greater Than', value: '>' },
      { text: 'Greater or Equal', value: '>=' },
      { text: 'Less Than', value: '<' },
      { text: 'Less or Equal', value: '<=' },
      { text: 'In List', value: 'in' }
    ])

    const valueOptions = ref([])

    function selectField ({ value, type, options, optionGroup, items }) {
      if (value != null) {
        emit('change', { field: value })
      }
      if (type === 'radio' || type === 'select' || type === 'autocomplete' || type === 'combobox') {
        opType.value = 'select'
        emit('change', { op: 'in' })
        if (options != null) {
          root.$feathers.service('forms/select-options').get(options).then(({ options }) => {
            valueOptions.value = options
          })
        } else if (optionGroup != null) {
          root.$feathers.service('forms/select-options').find({ query: { name: optionGroup } }).then((data) => {
            root.$feathers.service('forms/select-options').get(data[0].value).then((data) => {
              valueOptions.value = options
            })
          })
        } else if (items != null) {
          valueOptions.value = items
        }
      } else if (type === 'number') {
        opType.value = 'number'
      } else if (type === 'checkbox') {
        opType.value = 'checkbox'
        emit('change', { op: '=' })
      } else if (type === 'switch') {
        opType.value = 'switch'
        emit('change', { op: '=' })
      } else {
        opType.value = 'text'
      }
    }

    onBeforeMount(() => {
      if (props.field != null && props.field !== '') {
        for (let i = 0; i < props.fieldOptions.length; i++) {
          if (props.fieldOptions[i].value === props.field) {
            selectField(props.fieldOptions[i])
            break
          }
        }
      }
    })

    return {
      opType,
      operators,
      valueOptions,
      selectField
    }
  }
}
</script>
